import { Box, Drawer, Grid, Menu, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Images } from "../../../helpers/Images";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import "./NavBar.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import CollectionsIcon from "@mui/icons-material/Collections";
import ViewComfyAltIcon from "@mui/icons-material/ViewComfyAlt";
import PanoramaPhotosphereSelectOutlinedIcon from "@mui/icons-material/PanoramaPhotosphereSelectOutlined";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import CloseIcon from "@mui/icons-material/Close";

const NavBar = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [DrawerOpen, setDrawerOpen] = useState(false);
  const handleDrawer = (bool) => {
    setDrawerOpen(bool);
  };

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [openReg, setOpenReg] = useState(false);
  const [openBecome, setOpenBecome] = useState(false);
  const [accOpen, setAccOpen] = useState(false);
  const [downClose, setDownClose] = useState(false);

  return (
    <>
      {!downClose ? (
        <Box
          sx={{
            width: "100%",
            height: { xs: "70px", sm: "50px" },
            backgroundColor: "#000",
            position: "sticky",
            top: 0,
            zIndex: "1000",
            boxShadow: "0px 0px 20px 2px rgba(78,18,17,0.43)",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              padding: "5px",
              alignItems: "center",
              height: "100%",
              position: "relative",
              padding: "0 40px",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Typography
              sx={{
                color: "white",
                margin: { xs: "0 0 4px 0", sm: "5px 10px 0 0" },
                fontSize: { xs: "14px", sm: "17px" },
              }}
              className="regStart"
            >
              Registration Starts In:
            </Typography>
            <FlipClockCountdown
              className="flip-clock"
              to={"2025-02-10T24:00:00"}
              labelStyle={{
                display: "none",
              }}
              showLabels={false}
            />
            <CloseIcon
              onClick={() => setDownClose(true)}
              style={{
                fontSize: "25px",
                color: "white",
                position: "absolute",
                top: "13px",
                right: "8px",
                cursor: "pointer",
              }}
            />
          </Box>
        </Box>
      ) : (
        ""
      )}
      <Box
        sx={{
          width: "100%",
          height: "80px",
          backgroundColor: "secondary.main",
          position: "sticky",
          top: downClose ? 0 : { xs: "70px", sm: "50px" },
          zIndex: "1000",
          boxShadow: "0px 0px 20px 2px rgba(78,18,17,0.43)",
        }}
      >
        <Grid
          container
          alignItems={"center"}
          sx={{
            height: "100%",
            padding: { xs: "0 20px", sm: "0 40px", lg: "0 90px" },
            justifyContent: "space-between",
            maxWidth: "1500px",
            margin: "auto",
          }}
        >
          <Grid
            item
            xs={2}
            style={{ display: "flex", justifyContent: "start" }}
          >
            <img
              style={{ height: "60px", cursor: "pointer" }}
              src={Images.codeLogoHor}
              alt="Code Challenge Championship Logo"
              onClick={() => navigate("/")}
            />
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              justifyContent: "space-around",
              alignItems: "center",
              maxWidth: "600px",
              margin: "auto",
              display: { xs: "none !important", md: "flex !important" },
            }}
          >
            <Typography className="NavLinks" onClick={() => navigate("/")}>
              Home
            </Typography>
            <Box
              className="NavLinks registration" //registration
              // onClick={() => navigate("/firststage")}
            >
              Competition
              <ul className="navMenu">
                <li
                  onClick={() => navigate("/gallery")}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <CollectionsIcon
                    sx={{ fontSize: "17px", marginRight: "15px" }}
                  />
                  Highlights
                </li>
                <li
                  onClick={() => navigate("/tracks")}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <PanoramaPhotosphereSelectOutlinedIcon
                    sx={{ fontSize: "20px", marginRight: "15px" }}
                  />
                  Tracks
                </li>
                <li
                  onClick={() => navigate("/prizes")}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <EmojiEventsIcon
                    sx={{ fontSize: "20px", marginRight: "15px" }}
                  />
                  Prizes
                </li>
                <li
                  onClick={() => navigate("/leaderboard")}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <LeaderboardIcon
                    sx={{ fontSize: "20px", marginRight: "15px" }}
                  />
                  LeaderBoard
                </li>
              </ul>
            </Box>
            {/* <Typography
            className="NavLinks"
            onClick={() => navigate("/firststage")}
          >
            Registration
          </Typography> */}
            {/* <Typography
            className="NavLinks"
            onClick={() => navigate("/leaderboard")}
          >
            Leaderboard
          </Typography> */}

            <Box
              className="NavLinks registration" //registration
              // onClick={() => navigate("/firststage")}
            >
              Partners
              <ul className="navMenu">
                <li
                  onClick={() => navigate("/countries")}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Country Partner
                </li>
                <li
                  onClick={() => navigate("/academic")}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Academic Partner
                </li>
                <li
                  onClick={() => navigate("/sponsor")}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Sponsor
                </li>
              </ul>
            </Box>
            <Box
              className="NavLinks registration" //registration
              // onClick={() => navigate("/firststage")}
            >
              Accreditation
              <ul className="navMenu">
                <li
                  onClick={() => navigate("/accreditation/trainers")}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  For Trainers
                </li>
                <li
                  onClick={() => navigate("/accreditation/organizations")}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  For Organizations
                </li>
              </ul>
            </Box>
            <Typography
              className="NavLinks"
              onClick={() => navigate("/contact")}
            >
              Contact Us
            </Typography>
            <Typography className="NavLinks" onClick={() => navigate("/about")}>
              About Us
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: { xs: "none !important", md: "flex !important" },
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <a
              target="_blank"
              href="https://www.facebook.com/CodeChallengeChampionship"
              className="NavIconBox"
            >
              <FacebookIcon className="SocialNavIcon" />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/codechallengechampionship/"
              className="NavIconBox"
            >
              <InstagramIcon className="SocialNavIcon" />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/code-challenge-championship/"
              className="NavIconBox"
            >
              <LinkedInIcon className="SocialNavIcon" />
            </a>
            <a
              target="_blank"
              href="https://api.whatsapp.com/send?phone=%2B201500333864&fbclid=IwAR2Q0ES6pK05-OcugrLDDzFvd5qdd6SrzjAz07-6vnp_FUFVhxwE5pCxTXE"
              className="NavIconBox"
            >
              <WhatsAppIcon className="SocialNavIcon" />
            </a>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: { xs: "flex !important", md: "none !important" },
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <img
              alt="menu"
              className="SocialNavIcon"
              src={Images.menu}
              onClick={() => handleDrawer(true)}
            />
          </Grid>
        </Grid>
        <Drawer
          open={DrawerOpen}
          onClose={() => handleDrawer(false)}
          className="drawer"
        >
          <img
            style={{
              height: "60px",
              cursor: "pointer",
              width: "fit-content",
              margin: "20px 10px",
            }}
            src={Images.codeLogoHor}
            alt="Code Challenge Championship Logo"
            onClick={() => {
              navigate("/");
              handleDrawer(false);
            }}
          />
          <Typography
            className="NavLinks"
            onClick={() => {
              navigate("/");
              handleDrawer(false);
            }}
          >
            Home
          </Typography>
          <Typography
            className="NavLinksReg"
            onClick={() => {
              setOpenReg(!openReg);
            }}
          >
            Competition{" "}
            <KeyboardArrowRightIcon
              style={{ rotate: openReg ? "90deg" : "0deg" }}
            />
          </Typography>

          {openReg ? (
            <>
              <Typography
                className="NavLinks InsideLink"
                onClick={() => {
                  navigate("/gallery");
                  handleDrawer(false);
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CollectionsIcon
                    sx={{ fontSize: "17px", marginRight: "15px" }}
                  />
                  Highlights
                </div>
              </Typography>
              <Typography
                className="NavLinks InsideLink"
                onClick={() => {
                  navigate("/tracks");
                  handleDrawer(false);
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <PanoramaPhotosphereSelectOutlinedIcon
                    sx={{ fontSize: "20px", marginRight: "15px" }}
                  />
                  Tracks
                </div>
              </Typography>
              <Typography
                className="NavLinks InsideLink"
                onClick={() => {
                  navigate("/prizes");
                  handleDrawer(false);
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <EmojiEventsIcon
                    sx={{ fontSize: "20px", marginRight: "15px" }}
                  />
                  Prizes
                </div>
              </Typography>
              <Typography
                className="NavLinks InsideLink"
                onClick={() => {
                  navigate("/leaderboard");
                  handleDrawer(false);
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <LeaderboardIcon
                    sx={{ fontSize: "20px", marginRight: "15px" }}
                  />
                  LeaderBoard
                </div>
              </Typography>
            </>
          ) : (
            ""
          )}

          <Typography
            className="NavLinksReg"
            onClick={() => {
              setAccOpen(!accOpen);
            }}
          >
            Accreditation{" "}
            <KeyboardArrowRightIcon
              style={{ rotate: accOpen ? "90deg" : "0deg" }}
            />
          </Typography>
          {accOpen ? (
            <>
              <Typography
                className="NavLinks InsideLink"
                onClick={() => {
                  navigate("/accreditation/trainers");
                  handleDrawer(false);
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  For Trainers
                </div>
              </Typography>
              <Typography
                className="NavLinks InsideLink"
                onClick={() => {
                  navigate("/accreditation/organizations");
                  handleDrawer(false);
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  For Organizations
                </div>
              </Typography>
            </>
          ) : (
            ""
          )}
          {/* <Typography
          className="NavLinks"
          onClick={() => {
            navigate("/leaderboard");
            handleDrawer(false);
          }}
        >
          Leaderboard
        </Typography> */}
          {/* <Typography
          className="NavLinksReg"
          // onClick={() => {
          //   setOpenReg(!openReg);
          // }}
          onClick={() => {
            navigate("/firststage");
            handleDrawer(false);
          }}
        >
          Registration{" "}
          <KeyboardArrowRightIcon
            style={{ rotate: openReg ? "90deg" : "0deg" }}
          />
        </Typography> */}
          {openReg ? (
            <>
              {/* <Typography
              className="NavLinks InsideLink"
              onClick={() => {
                navigate("/firststage");
                handleDrawer(false);
              }}
            >
              First Registration
            </Typography> */}
              {/* <Typography
              className="NavLinks InsideLink"
              onClick={() => {
                navigate("/laststage");
                handleDrawer(false);
              }}
            >
              Final Registration
            </Typography>
            <Typography
              className="NavLinks InsideLink"
              onClick={() => {
                navigate("/check");
                handleDrawer(false);
              }}
            >
              Check Application
            </Typography> */}
            </>
          ) : (
            ""
          )}
          <Typography
            className="NavLinksReg"
            onClick={() => {
              setOpenBecome(!openBecome);
            }}
          >
            Become A Partner{" "}
            <KeyboardArrowRightIcon
              style={{ rotate: openBecome ? "90deg" : "0deg" }}
            />
          </Typography>

          {openBecome ? (
            <>
              <Typography
                className="NavLinks InsideLink"
                onClick={() => {
                  navigate("/countries");
                  handleDrawer(false);
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  Country Partner
                </div>
              </Typography>
              <Typography
                className="NavLinks InsideLink"
                onClick={() => {
                  navigate("/academic");
                  handleDrawer(false);
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  Academic Partner
                </div>
              </Typography>
              <Typography
                className="NavLinks InsideLink"
                onClick={() => {
                  navigate("/sponsor");
                  handleDrawer(false);
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  Sponsor
                </div>
              </Typography>
            </>
          ) : (
            ""
          )}
          {/* <Typography
          className="NavLinks"
          onClick={() => {
            navigate("/leaderboard");
            handleDrawer(false);
          }}
        >
          Leaderboard
        </Typography> */}
          {/* <Typography
          className="NavLinksReg"
          // onClick={() => {
          //   setOpenReg(!openReg);
          // }}
          onClick={() => {
            navigate("/firststage");
            handleDrawer(false);
          }}
        >
          Registration{" "}
          <KeyboardArrowRightIcon
            style={{ rotate: openReg ? "90deg" : "0deg" }}
          />
        </Typography> */}
          {openReg ? (
            <>
              {/* <Typography
              className="NavLinks InsideLink"
              onClick={() => {
                navigate("/firststage");
                handleDrawer(false);
              }}
            >
              First Registration
            </Typography> */}
              {/* <Typography
              className="NavLinks InsideLink"
              onClick={() => {
                navigate("/laststage");
                handleDrawer(false);
              }}
            >
              Final Registration
            </Typography>
            <Typography
              className="NavLinks InsideLink"
              onClick={() => {
                navigate("/check");
                handleDrawer(false);
              }}
            >
              Check Application
            </Typography> */}
            </>
          ) : (
            ""
          )}
          <Typography
            className="NavLinks"
            onClick={() => {
              navigate("/contact");
              handleDrawer(false);
            }}
          >
            Contact Us
          </Typography>
          <Typography
            className="NavLinks"
            onClick={() => {
              navigate("/about");
              handleDrawer(false);
            }}
          >
            About Us
          </Typography>
        </Drawer>
      </Box>
    </>
  );
};

export default NavBar;

// RobotChallenge is one of the biggest competitions for self-made, autonomous and mobile robots worldw
