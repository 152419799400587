import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Images } from "../../../../helpers/Images";
import { categoriesData } from "../../../../helpers/Arrays";
import { Gallery, GalleryArray } from "../../../../helpers/gallery";

const WebTemplate = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        {false ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant={"h1"}
              sx={{
                textAlign: "start",
                fontSize: { xs: "40px", sm: "70px" },
                fontWeight: "500",
                color: "primary.darker",
                margin: "100px auto 0 auto",
              }}
            >
              COMING SOON
            </Typography>
            <Box
              sx={{
                height: "3px",
                maxWidth: { xs: "100px", md: "300px" },
                width: "100%",
                backgroundColor: "primary.main",
                margin: "5px auto 5px auto",
              }}
            />
            <Typography
              variant={"h2"}
              sx={{
                textAlign: "start",
                fontSize: { xs: "40px", sm: "40px" },
                fontWeight: "500",
                color: "primary.darker",
                margin: "5px auto 100px auto",
              }}
            >
              Code Challenge
            </Typography>
          </Box>
        ) : (
          <Grid
            container
            spacing={4}
            alignItems={"start"}
            justifyContent={"space-between"}
          >
            <Grid item xs={12}>
              <Typography
                variant={"h1"}
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "40px", sm: "55px" },
                  fontWeight: "500",
                  color: "#010101",
                }}
              >
                Web Challenge
              </Typography>
              <Box
                sx={{
                  height: "3px",
                  maxWidth: { xs: "100px", md: "150px" },
                  width: "100%",
                  backgroundColor: "#db2422",
                  margin: "10px auto",
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1fJNm6vlMX8SpXNVQWo3RZ5P2m-DJx-PH"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "17px",
                      margin: "5px",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                    }}
                  >
                    Download General Rules (PDF)
                  </Button>
                </a>
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1zP1VE9RoMHJAJDuhQZwYq6XPDmYixfyU"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "17px",
                      margin: "5px",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                    }}
                  >
                    Download Web Rules (PDF)
                  </Button>
                </a>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1ShfsVJKX14C8VP6YSGrQNMAKpUqZdYpZ"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "17px",
                      margin: "5px 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                    }}
                  >
                    Download Web Challenge 2024 Scoring Sheet
                  </Button>
                </a>
              </Box>
              {/* <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    fontSize: "17px",
                    margin: "5px 0",
                    fontWeight: "600",
                    backgroundColor: "#db2422",
                  }}
                  onClick={() => navigate("/firststage")}
                >
                  Register Now
                </Button>
              </Box> */}
              <Typography
                sx={{
                  textAlign: "left",
                  color: "primary.darker",
                  // maxWidth: "750px",
                  margin: "25px 0 10px 0",
                  fontSize: "17px",
                }}
              >
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "30px" },
                    fontWeight: "500",
                    color: "#010101",
                    marginTop: "50px",
                    marginBottom: "10px",
                  }}
                >
                  Brief:
                </Typography>
                Web development is the process of creating websites and online
                applications using programming languages. It's a versatile field
                with opportunities for both beginners and experienced
                developers. Mastering web development can open doors to various
                career paths and projects in today's digital landscape. Join us
                this year in our Web Challenge to enhance your skills and build
                incredible projects!
                <br />
                <br />
                In Web Challenge, there are{" "}
                <strong>three main categories and three main rounds</strong> in
                each category with different challenges. Each participant/team
                should finish the challenge and submit their work in specified
                time !..
                <br />
                <br />
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "28px" },
                    fontWeight: "500",
                    color: "#010101",
                    marginTop: "20px",
                  }}
                >
                  Junior Category:
                </Typography>
                <Box
                  sx={{
                    height: "3px",
                    maxWidth: { xs: "100px", md: "150px" },
                    width: "100%",
                    backgroundColor: "#db2422",
                    margin: "10px 0 0 0",
                  }}
                />
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Age:
                </span>{" "}
                10 - 13
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Team size:
                </span>{" "}
                1 - 3 people guided by a coach <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Software:
                </span>{" "}
                HTML & CSS (VS code or Atom)
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1WG7hpYZgFY68oNmpA0RKxbnQAJOmcFyt"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Web Junior 2024 Mission 1
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1koM8u9hF48G7dNPh14tE5-0F2yX7XNXF"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Web Junior 2024 Mission 2
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1hQkyRJAUmiJoCw5w-tv32hze7LxsIsVp"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Web Junior 2024 Mission 3
                  </Button>
                </a>
                <br />
                <a
                  target="_blank"
                  href={
                    "https://drive.google.com/drive/folders/17WnmqRyMSTX9-JbewWiHVmSPm35MrJs5?usp=sharing"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Web Junior 2023 Mission 1
                  </Button>
                </a>
                <br />
                <a
                  target="_blank"
                  href={
                    "https://drive.google.com/drive/folders/1hDFggd70HD-8IhbNKQlJKShbxlkM-MK_?usp=sharing"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Web Junior 2023 Mission 2
                  </Button>
                </a>
                <br />
                <a
                  target="_blank"
                  href={
                    "https://drive.google.com/drive/folders/1OUmLZ1bSrzhxmqwM-OzLve1ps9g_ULhz?usp=sharing"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Web Junior 2023 Mission 3
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1Ivwve1tovmPa1A8Fu4apaqzlVG-qs7-E"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Download Web Junior 2023 samples
                  </Button>
                </a>
                <br />
                <a
                  target="_blank"
                  href={
                    "https://drive.google.com/drive/folders/1dAStV-hqxFM_u6Bem0Yybs9JZBx5w3g5"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Web Junior 2023 samples answers
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1nlXfCum1Il2Mz4djgLZQEcKz9RQeYrIh"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Download Web Junior Handbook
                  </Button>
                </a>
                <br />
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "28px" },
                    fontWeight: "500",
                    color: "#010101",
                    marginTop: "40px",
                  }}
                >
                  Senior Category:
                </Typography>
                <Box
                  sx={{
                    height: "3px",
                    maxWidth: { xs: "100px", md: "150px" },
                    width: "100%",
                    backgroundColor: "#db2422",
                    margin: "10px 0 0 0",
                  }}
                />
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Age:
                </span>{" "}
                14 - 17
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Team size:
                </span>{" "}
                1 - 3 people guided by a coach
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Software:
                </span>{" "}
                HTML & CSS (VS code or Atom)
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1X19MTHQ6YpUylunnQ5HAZ3TKfJeRO8sC"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Web Senior 2024 Mission 1
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1N7aoK4QM5o-Qyh8ybjakwNPX5hJy-CCs"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Web Senior 2024 Mission 2
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1TPpnpe8Cpho16dO6haKrXyfBvsmVmGJc"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Web Senior 2024 Mission 3
                  </Button>
                </a>
                <br />
                <a
                  target="_blank"
                  href={
                    "https://drive.google.com/drive/folders/1QhEeM3e8Hlv8MXSwiE6pvWwm9Qti_UcD?usp=sharing"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Web Senior 2023 Mission 1
                  </Button>
                </a>
                <br />
                <a
                  target="_blank"
                  href={
                    "https://drive.google.com/drive/folders/1nhquEcHDoSRVUg0pZ7kIa8PBBwiacJJe?usp=sharing"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Web Senior 2023 Mission 2
                  </Button>
                </a>
                <br />
                <a
                  target="_blank"
                  href={
                    "https://drive.google.com/drive/folders/1JVJ6vHXU_os1BDU4AbncSVH8cH_Xztgt?usp=sharing"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Web Senior 2023 Mission 3
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=10vxnjh3Kr5X3hfhPxKidqj8XZ676b343"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Download Web Senior 2023 samples
                  </Button>
                </a>
                <br />
                <a
                  target="_blank"
                  href={
                    "https://drive.google.com/drive/folders/1dAStV-hqxFM_u6Bem0Yybs9JZBx5w3g5"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Web Senior 2023 samples answers
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1yJbHGQ_zLa9RddHxLQmWkS0cf0HBQMdi"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Download Web Senior Handbook
                  </Button>
                </a>
                <br />
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "28px" },
                    fontWeight: "500",
                    color: "#010101",
                    marginTop: "40px",
                  }}
                >
                  Adult Category:
                </Typography>
                <Box
                  sx={{
                    height: "3px",
                    maxWidth: { xs: "100px", md: "150px" },
                    width: "100%",
                    backgroundColor: "#db2422",
                    margin: "10px 0 0 0",
                  }}
                />
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Age:
                </span>{" "}
                18 - 24
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Team size:
                </span>{" "}
                1 - 3 people guided by a coach
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Software:
                </span>{" "}
                HTML & CSS & JS (VS code or Atom)
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1PGOyxWZ9Uz4rHzaVbI0qtLBbwVDvY8b6"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Web Adult 2024 Mission 1
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1pIKLQxUNZwd5vMro1oP8kcPqikJh4lxi"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Web Adult 2024 Mission 2
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1ZixT_ED01aJv1_A8zzXfdU-c7YaecaRZ"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Web Adult 2024 Mission 3
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1Zk-1xHiwyBvscFQhoQM6-zAXIsa20WW4"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Download Web Adult 2024 sample
                  </Button>
                </a>
                <br />
              </Typography>
              <br />
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent={"center"}
              sx={{
                marginTop: "20px",
                justifyContent: "center",
                marginLeft: "16px",
                marginBottom: "20px",
              }}
            >
              {GalleryArray?.sort((a, b) => 0.5 - Math.random())
                ?.slice(0, 7)
                ?.map((item, i) => (
                  <Grid item xs={12} sm={6} md={3} key={i}>
                    <img
                      loading={"lazy"}
                      alt={i}
                      src={item}
                      style={{ width: "100%", borderRadius: "10px" }}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "primary.main",
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
            maxWidth: "1500px",
            margin: "auto",
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              textAlign: "center",
              fontSize: { xs: "40px" },
              fontWeight: "500",
              color: "secondary.main",
            }}
          >
            Our All Tracks
          </Typography>
          <Box
            sx={{
              height: "3px",
              maxWidth: { xs: "100px", md: "150px" },
              width: "100%",
              backgroundColor: "secondary.main",
              margin: "10px auto 0 auto",
            }}
          />
          <Grid
            container
            spacing={2}
            sx={{ marginTop: "50px", justifyContent: "center" }}
          >
            {categoriesData
              ?.filter((category) => category.title !== "Web Challenge")
              ?.map((item) => (
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${item.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      width: "100%",
                      minHeight: { xs: "200px", sm: "250px" },
                      borderRadius: "10px",
                    }}
                  >
                    <Typography
                      variant={"h3"}
                      sx={{
                        textAlign: "center",
                        fontSize: "35px",
                        fontWeight: "500",
                        color: "secondary.main",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Box
                      sx={{
                        height: "3px",
                        maxWidth: "90px",
                        width: "100%",
                        backgroundColor: "secondary.main",
                        margin: "5px auto 5px auto",
                      }}
                    />
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          fontSize: "13px",
                          fontWeight: "600",
                          color: "primary.main",
                          width: "fit-content",
                          margin: { xs: "5px 0 20px 0", sm: "10px 0 40px 0" },
                        }}
                        onClick={() => navigate(item.link)}
                      >
                        view
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default WebTemplate;
