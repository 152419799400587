import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GalleryPage from "./Pages/Gallery/Gallery.page";
import HomePage from "./Pages/Home/Home.page";
import ContactPage from "./Pages/Contact/Contact.page";
import AboutPage from "./Pages/About/About.page";
import NavBar from "./Components/organisms/NavBar/NavBar";
import Footer from "./Components/organisms/Footer/Footer";
import CategoriesPage from "./Pages/Categories/Categories.page";
import CodePage from "./Pages/Categories/Details/Web.page";
import { Box } from "@mui/system";
import KidPage from "./Pages/Categories/Details/Kid.page";
import AIPage from "./Pages/Categories/Details/AI.page";
import WebPage from "./Pages/Categories/Details/Web.page";
import PythonPage from "./Pages/Categories/Details/Python.page";
import FirstStagePage from "./Pages/Registration/FirstStage/FirstStage.page";
import CheckApplicationPage from "./Pages/Registration/CheckApplication/CheckApplication.page";
import LastStagePage from "./Pages/Registration/LastStage/LastStage.page";
import PrizesPage from "./Pages/Prizes/Prizes.page";
import PaymentResultPage from "./Pages/PaymentResult/PaymentResult.page";
import PrivacyPage from "./Pages/Privacy/Privacy.page";
import TermsPage from "./Pages/Terms/Terms.page";
import RefundPage from "./Pages/Refund/Refund.page";
import LeaderBoardPage from "./Pages/LeaderBoard/LeaderBoard.page";
import LeaderBoardDetailsPage from "./Pages/LeaderBoard/LeaderBoardDetails/LeaderBoardDetails";
import ReportsPage from "./Pages/Reports/Reports.page";
import CountriesPage from "./Pages/Countries/Countries.page";
import AcademicPage from "./Pages/Academic/Academic.page";
import SponsorPage from "./Pages/Sponsor/Sponsor.page";
import FirstStageConfirmPage from "./Pages/Registration/FirstStageConfirm/FirstStageConfirm.page";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TrainersAccPage from "./Pages/TrainersAcc/TrainersAcc.page";
import OrganizationAccPage from "./Pages/OrganizationAcc/OrganizationAcc.page";

const theme = createTheme({
  palette: {
    primary: {
      light: "#ac1e1c",
      main: "#6a0505",
      darker: "#010102",
      // contrastText: "#010102",
    },
    secondary: {
      light: "#71b4b2",
      main: "#fff",
      dark: "#a4a4a3",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <NavBar />
        {/* <BucketList /> */}
        <Box sx={{ width: "100%", flex: "1" }}>
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/gallery" element={<GalleryPage />} />
            <Route exact path="/contact" element={<ContactPage />} />
            <Route exact path="/about" element={<AboutPage />} />
            <Route exact path="/prizes" element={<PrizesPage />} />
            <Route
              exact
              path="/accreditation/trainers"
              element={<TrainersAccPage />}
            />
            <Route
              exact
              path="/accreditation/organizations"
              element={<OrganizationAccPage />}
            />
            <Route exact path="/payment" element={<PaymentResultPage />} />
            <Route exact path="/privacy" element={<PrivacyPage />} />
            <Route exact path="/terms" element={<TermsPage />} />
            <Route exact path="/refund" element={<RefundPage />} />
            {/* <Route exact path="/firststage" element={<FirstStagePage />} />
            <Route
              exact
              path="/firststage/registered"
              element={<FirstStageConfirmPage />}
            /> */}
            {/* <Route path="/laststage" element={<LastStagePage />} />
            <Route path="/laststage/:code" element={<LastStagePage />} />
            <Route path="/check" element={<CheckApplicationPage />} />
            <Route path="/check/:code" element={<CheckApplicationPage />} /> */}
            <Route path="/tracks" element={<CategoriesPage />} />
            <Route path="/reports" element={<ReportsPage />} />
            <Route path="/countries" element={<CountriesPage />} />
            <Route path="/academic" element={<AcademicPage />} />
            <Route path="/sponsor" element={<SponsorPage />} />
            <Route path="/leaderboard" element={<LeaderBoardPage />} />
            <Route
              path="/leaderboard/:id"
              element={<LeaderBoardDetailsPage />}
            />
            <Route path="/competition/kidcoder" element={<KidPage />} />
            <Route path="/competition/web" element={<WebPage />} />
            <Route path="/competition/python" element={<PythonPage />} />
            <Route path="/competition/ai" element={<AIPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Box>
        <ToastContainer />
        <Footer />
        <a
          target="_blank"
          href="https://api.whatsapp.com/send?phone=%2B201500333864&fbclid=IwAR2Q0ES6pK05-OcugrLDDzFvd5qdd6SrzjAz07-6vnp_FUFVhxwE5pCxTXE"
          style={{ textDecoration: "none" }}
        >
          <Box className="WhatsappCont">
            <WhatsAppIcon className="WhatsappIcon" />
          </Box>
        </a>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
